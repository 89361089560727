const programType = [
  {
    label: 'Коммерческий',
    value: '',
  },

  {
    label: 'Бюджетный',
    value: '',
  },

]

export default programType