const streamType = [
  {
    label: 'Уфанет (внутренний)',
    value: ''
  },

  {
    label: 'UTV (студия)',
    value: ''
  },

  {
    label: 'Коммерческий (студия)',
    value: ''
  },

  {
    label: 'Коммерческий (выездной)',
    value: ''
  },
]


export default streamType