const sampleProject = [
  {value: 'personalFinance', label: 'Личные Финансы', path: 'путь до сырья \\utv2\\UTV\\Передачи\\Личные финансы'},
  {value: 'oneOfUs', label: 'Один из нас', path: '\\utv2\\UTV\\Передачи\\Один из нас'},
  {value: 'snyaliDvushku', label: 'Сняли двушку', path: '\\utv2\\UTV\\Передачи\\Пилот Рафы'},
  {value: 'eaters', label: 'Едоки', path: '\\utv2\\UTV\\Передачи\\Едоки'},
  {value: 'personalArea', label: 'Личный кабинет', path: ''},
  {value: 'ufaAtlet', label: 'Уфа Атлет', path: '\\utv2\\UTV\\Передачи\\Ufa Атлет'},
  {value: 'miniAfisha', label: 'Мини Афиша', path: '\\utv2\\UTV\\Передачи\\Афиша'},
  {value: 'ourHouse', label: 'Наш дом', path: '\\utv2\\UTV\\Передачи\\Наш дом'},
]


export default sampleProject;