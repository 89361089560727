const operatorCotegory = [

  {
    label: 'ПРОСТАЯ СЪЁМКА',
    value: 'простая съёмка'
  },

  {
    label: 'ДЕЖУРНЫЙ',
    value: '8 часов'
  },

  {
    label: 'РЕЗЕРВ 8часовой',
    value: '8 часов'
  },

  {
    label: 'РЕЗЕРВ 11часовой',
    value: '11 часов'
  },

  {
    label: 'ВЫХОДНОЙ',
    value: 'выходной'
  },

  {
    label: 'ОТПУСК',
    value: 'отпуск'
  }
]

export default operatorCotegory