const designParts = [
  {
    label: 'Оформление передачи',
    value: '1'
  },

  {
    label: 'заставка',
    value: '2'
  },

  {
    label: 'плашка',
    value: '3'
  },

  {
    label: 'титры',
    value: '4'
  },

  {
    label: 'пекшот',
    value: '5'
  },


  {
    label: 'новости карта',
    value: '6'
  },

  {
    label: 'новости цитата',
    value: '7'
  },

  {
    label: 'инфографика для новостей',
    value: '8'
  },

  {
    label: 'графика для новостей',
    value: '9'
  },

  {
    label: '3D',
    value: '10'
  },

]


export default designParts



