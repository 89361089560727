const userPrice = [

  {
    label: 'Пилотный проект',
    value: 'не определено'
  },

  {
    label: 'Разовый проект',
    value: 'не определено'
  },

  {
    label: 'UTV',
    value: 'не определено'
  },

  {
    label: 'ЖИВИ АКТИВНО',
    value: 'не определено'
  },

  {
    label: 'ЖИВАЯ ПРИРОДА',
    value: 'не определено'
  },

  {
    label: 'ГЛАЗАМИ ТУРИСТА',
    value: 'не определено'
  },

  {
    label: 'УФАНЕТ(продакшен)',
    value: 'не определено'
  },

  {
    label: 'ВНЕШНИЙ(продакшен)',
    value: 'не определено'
  },

  {
    label: 'Новости',
    value: 'не определено'
  },

  {
    label: 'Сумико',
    value: 'не определено'
  },

  {
    label: 'Ютуб-дежурство',
    value: 'не определено'
  },

  {
    label: 'Рилс/шортс по сценарию автора',
    value: 'не определено'
  },

  {
    label: 'Тизер-трейлер к передаче',
    value: 'не определено'
  },

  {
    label: 'Трейлер телеканала',
    value: 'не определено'
  },


]


export default userPrice