const programCotegory = [

  {
    label: 'типовой проект',
    value: 'типовой проект',
  },

  {
    label: 'новый проект',
    value: 'новый проект',
  },


  {
    label: 'шаблонный проект',
    value: 'шаблонный проект',
  },




]

export default programCotegory